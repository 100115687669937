import { useCallback } from "react";
import { useRouter } from "next/navigation";
import { v4 } from "uuid";
import { useQueryClient } from "@tanstack/react-query";

import Button from "components/Button";
import useTableActionsState from "hooks/useTableActionsState";
import { ButtonAppearance, CellType, SIDEBAR_TYPE, STATIC_SIDEBAR_IDS, ViewOption } from "utils/constants";

const AddColumnButton = ({
  tableName,
  pageId,
  tableSlug,
  currentView
}: {
  tableName: string;
  pageId: string;
  tableSlug: string;
  currentView: ViewOption;
}) => {
  const router = useRouter();
  const queryClient = useQueryClient();
  const { updateSidebarState } = useTableActionsState();

  const onColumnAdded = useCallback(() => {
    router.refresh();
    // Refetch page data for updated column config
    queryClient.invalidateQueries({ queryKey: ["page", pageId] });
    queryClient.invalidateQueries({ queryKey: ["page", tableSlug] });
    // Refetch table data
    queryClient.invalidateQueries({ queryKey: ["table", tableName] });
  }, [queryClient, pageId, tableSlug, tableName, router]);

  const handleAddColumnToPage = useCallback(() => {
    const newCol = {
      id: `new_${v4()}`,
      header: "",
      type: CellType.TEXT,
      name: ""
    };
    updateSidebarState(
      {
        isOpen: true,
        sidebarType: SIDEBAR_TYPE.ADMIN_COLUMN_EDIT,
        onActionComplete: onColumnAdded,
        additionalProps: {
          column: newCol,
          pageSlug: tableSlug,
          parentTableName: tableName,
          currentView,
          pageId,
          isNew: true
        }
      },
      STATIC_SIDEBAR_IDS.COLUMN_CONFIG_EDIT_SIDEBAR
    );
  }, [updateSidebarState, currentView, onColumnAdded, tableSlug, tableName, pageId]);

  const handleAddExistingColumnToPage = useCallback(() => {
    updateSidebarState(
      {
        showDetailView: false,
        isOpen: false,
        pageViewConfigEditState: {
          isOpen: true,
          pageId: pageId || "",
          view: currentView,
          onActionComplete: onColumnAdded
        }
      },
      STATIC_SIDEBAR_IDS.PAGE_VIEW_CONFIG_EDIT_SIDEBAR
    );
  }, [updateSidebarState, currentView, onColumnAdded, pageId]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <Button label="Add Column" appearance={ButtonAppearance.PRIMARY} onClick={handleAddColumnToPage} />
        <Button
          className="ml-5"
          label="Add Existing Column"
          appearance={ButtonAppearance.SECONDARY}
          onClick={handleAddExistingColumnToPage}
        />{" "}
      </div>
    </div>
  );
};

export default AddColumnButton;
