import { useCallback, useEffect, useState, useRef } from "react";
import { UseQueryOptions, useQueries } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import useCurrentUser from "hooks/useCurrentUser";
import useSchemaState from "hooks/useSchemaState";
import useRecordTypes from "hooks/useRecordTypes";
import { getTableData } from "lib/supabaseApi";
import { RecordItem } from "types/common";
import { TableColumnType, TableFilterType } from "types/baTypes";
import { ApiRecordType } from "types/apiTypes";

export type MultiQuery = {
  tableName: string;
  columnNames: string[];
  field: string;
  operator: string;
  value: string;
  resultId: string;
};

export type MultiPageColumns = {
  tableName: string;
  columns: TableColumnType[];
  filters?: TableFilterType[];
  resultId: string;
  matchingRecordType?: ApiRecordType;
  returnCountOnly?: boolean;
  isAggregateFetch?: boolean;
};

const useMultiTableData = (source?: string) => {
  const supabaseClient = useSupabaseBrowser();
  const [finalQueries, setFinalQueries] = useState<UseQueryOptions[]>([]);
  const [finalResults, setFinalResults] = useState<RecordItem>();
  const [allQueries, setAllQueries] = useState<RecordItem[]>([]);
  const resultsSet = useRef<boolean>();
  const currentUser = useCurrentUser();
  const { schemaInstance, schema } = useSchemaState();
  const { data: recordTypesData } = useRecordTypes();

  const results = useQueries({
    queries: finalQueries
  });

  // Uses getTableData instead of the supabaseClient directly
  const setMultiTablesData = useCallback(
    (queries: MultiPageColumns[]) => {
      if (!schema) return;
      const fetchQueries: UseQueryOptions[] = [];
      queries.forEach((query) => {
        const { tableName, columns, filters, returnCountOnly, isAggregateFetch } = query;
        fetchQueries.push({
          queryKey: [query.tableName, query.columns?.map((col) => col.id), query.filters],
          queryFn: async () => {
            return await getTableData({
              tableName,
              columns,
              tableFiltersOption: filters ? { filters } : undefined,
              supabaseClient,
              currentUser,
              recordTypesData,
              extendedSchema: schemaInstance?.extendedSchema,
              returnCountOnly,
              isAggregateFetch
            });
          },
          staleTime: Infinity
        });
      });
      if (fetchQueries?.length) {
        setFinalQueries(fetchQueries);
        setAllQueries(queries);
      }
    },
    [schemaInstance, schema, supabaseClient, currentUser, recordTypesData]
  );

  const setMultiQueries = useCallback(
    (queries: MultiQuery[]) => {
      const fetchQueries: UseQueryOptions[] = [];
      queries.forEach((query) => {
        fetchQueries.push({
          queryKey: [query.tableName, query.columnNames],
          queryFn: async () => {
            return await supabaseClient
              .from(query.tableName)
              .select(query.columnNames.join(","))
              .eq(query.field, query.value);
          },
          staleTime: Infinity
        });
      });
      if (fetchQueries?.length) {
        setFinalQueries(fetchQueries);
        setAllQueries(queries);
      }
    },
    [supabaseClient]
  );

  useEffect(() => {
    if (
      !results?.length ||
      results?.some((result) => !!result.isLoading) ||
      !allQueries?.length ||
      !finalQueries?.length ||
      resultsSet.current
    ) {
      return;
    }
    const updatedResults: RecordItem = {};
    results.forEach((result, index) => {
      const query = allQueries[index];
      const data: any = result?.data;
      if ((!query.returnCountOnly && data?.data?.length) || query.returnCountOnly) {
        updatedResults[query.resultId] = query.returnCountOnly ? data?.count || 0 : data?.data;
        resultsSet.current = true;
      }
    });
    if (resultsSet.current) {
      setFinalResults(updatedResults);
    }
  }, [results, finalQueries, allQueries, source]);

  return {
    results: finalResults,
    setMultiQueries,
    setMultiTablesData
  };
};

export default useMultiTableData;
