import { useMemo } from "react";
import { SupabaseClient } from "@supabase/supabase-js";
import { useQuery } from "@tanstack/react-query";

import { getTableDataById } from "lib/supabaseApi";

import { Schema } from "utils/schema";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getCompositeKeysFromCols } from "utils/dataUtils";

import useSchemaState from "hooks/useSchemaState";
import useCurrentUser from "hooks/useCurrentUser";

import { TableColumnType } from "types/baTypes";
import { QueryHookOptions } from "types/common";
import { User } from "types/apiTypes";

type TableInfoProps = {
  id: string;
  tableName: string | undefined;
  columns?: TableColumnType[];
};

export const fetchTablesData = async (
  tablesInfo: TableInfoProps[],
  supabaseClient: SupabaseClient,
  schemaInstance: Schema | null,
  currentUser?: User
) => {
  const results = await Promise.all(
    tablesInfo.map((tableInfo) => {
      const compositeKeysByTable = getCompositeKeysFromCols(tableInfo.columns, schemaInstance?.extendedSchema);

      return getTableDataById(
        {
          tableName: tableInfo.tableName || "",
          id: tableInfo.id,
          columns: tableInfo.columns,
          source: "useMultipleTablesData",
          organizationId: currentUser?.org_id || ""
        },
        supabaseClient,
        compositeKeysByTable,
        schemaInstance?.extendedSchema
      );
    })
  );
  return results.map((result) => result?.data?.[0]);
};

const useMultipleTablesData = (tablesInfo: TableInfoProps[], options?: QueryHookOptions) => {
  const supabaseClient = useSupabaseBrowser();
  const { schemaInstance } = useSchemaState();
  const currentUser = useCurrentUser();

  const queryKey = useMemo(() => {
    return ["records", tablesInfo.map((t) => `${t.tableName}_${t.id}`)];
  }, [tablesInfo]);

  const { data, isLoading, refetch } = useQuery({
    queryKey,
    queryFn: () => fetchTablesData(tablesInfo, supabaseClient, schemaInstance, currentUser),
    enabled: !!tablesInfo.length,
    ...options
  });

  return {
    data,
    isLoading: isLoading && !!tablesInfo.length,
    refetch
  };
};

export default useMultipleTablesData;
