import { useEffect } from "react";

import { CardApi, GridApi } from "context/TableActionsContext";
import { RecordItem } from "types/common";
import { isTargetNodeInSearchContainer } from "components/Search/utils";

type UseKeypressNavigationViewProps = {
  data: RecordItem[];
  currentId?: string | null;
  enabled: boolean;
  apiRef: React.RefObject<CardApi> | React.RefObject<GridApi>;
  onEnter: (row: RecordItem) => void;
};

const useKeypressNavigationView = ({ data, enabled, currentId, onEnter, apiRef }: UseKeypressNavigationViewProps) => {
  useEffect(() => {
    const handleKeypressNavigation = (e: KeyboardEvent) => {
      const wasInSearchContainer = isTargetNodeInSearchContainer(e.target as HTMLElement);

      if (!enabled || wasInSearchContainer) return;

      const isUp = e.key === "ArrowUp";
      const isDown = e.key === "ArrowDown";
      const isEnter = e.key === "Enter";

      if ((isUp || isDown) && !e.metaKey && !e.altKey) {
        e.preventDefault();

        const currentIndex = data.findIndex((item) => item.id === currentId);
        const finalIndex = currentIndex === -1 ? 0 : isDown ? currentIndex + 1 : currentIndex - 1;

        if (finalIndex < 0 || finalIndex > data?.length - 1) return;

        const id = data[finalIndex]?.id;

        const apiRefSelect = (apiRef.current as CardApi)?.selectCard || (apiRef.current as GridApi)?.selectRow;

        apiRefSelect(id);
      } else if (isEnter) {
        const row = data.find((row) => row.id === currentId);

        if (!row) return;

        onEnter(row);
      }
    };

    document.addEventListener("keydown", handleKeypressNavigation);
    return () => document.removeEventListener("keydown", handleKeypressNavigation);
  }, [data, enabled, currentId, apiRef, onEnter]);
};

export default useKeypressNavigationView;
