import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getDefaultTablePages } from "lib/adminApi";
import { QueryHookOptions } from "types/common";
import useCurrentUser from "hooks/useCurrentUser";

const useDefaultTablePages = (options?: QueryHookOptions) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["pages-default-table", currentUser?.org_id],
    queryFn: () => getDefaultTablePages(supabaseClient, currentUser?.org_id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
    ...options
  });

  return {
    data: data?.data,
    isLoading,
    refetch
  };
};

export default useDefaultTablePages;
