import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import { useCallback, useEffect, useMemo, useState } from "react";
import useRelationPages from "hooks/useRelationPages";
import {
  FormInput,
  RelationRecordsData,
  isFormConditionalColumnToShow,
  getRelationFormColumnPagesMap,
  getRollupFormulaColumnValues
} from "components/Form/utils";
import { getUpdatedFormulaColumns } from "components/DetailView/utils";
import {
  ExternalDefaultValue,
  FormJoinTableRelationInfo,
  Page,
  TableColumnType,
  TableViewConfig,
  ViewSection
} from "types/baTypes";
import { DETAIL_TAB_VISIBILITY, RecordItem, SelectOption } from "types/common";
import { appendRecordTypeLookupToCol } from "utils/columnUtils";
import {
  AdditionalFormActions,
  CellType,
  CrudActions,
  FORM_TRIGGER_TYPES,
  TEXT_TYPE_CELLS,
  ViewOption
} from "utils/constants";
import { getParentRecord } from "utils/dataUtils";
import { getFormColumnInitValue, getFormColumns } from "utils/formUtils";
import useAllPagesLite from "./useAllPagesLite";
import useCurrentUser from "./useCurrentUser";
import useMultiTableData, { MultiQuery } from "./useMultiTableData";
import useMultipleTablesData from "./useMultipleTablesData";
import usePageDataById from "./usePageDataById";
import useRecordTypes from "./useRecordTypes";
import useSchemaState from "./useSchemaState";
import useTableActionsState from "./useTableActionsState";
import useTableDataById from "./useTableDataById";
import useUserType from "./useUserType";
import useSearchQueryParams from "./useSearchQueryParams";

type TransformFormDataProps = {
  columns?: TableColumnType[] | null;
  tablePath?: string;
  parentRecordId?: string;
  parentRecordSlug?: string;
  action?: CrudActions | AdditionalFormActions;
  record?: RecordItem;
  isInlineCreate?: boolean;
  prefillValues?: RecordItem;
  tabSlug?: string;
  pageData?: Page;
  isCustomView?: boolean;
  pageFormViewId?: string;
  pageViewSections?: TableViewConfig[] | null;
  customViewSections?: ViewSection[];
  trigger?: FORM_TRIGGER_TYPES;
};
const useTransformFormData = ({
  tablePath,
  parentRecordId,
  parentRecordSlug,
  record,
  action,
  columns,
  isInlineCreate,
  prefillValues,
  tabSlug,
  pageData,
  isCustomView,
  pageFormViewId,
  pageViewSections,
  customViewSections,
  trigger
}: TransformFormDataProps) => {
  const [formFields, setFormFields] = useState<Record<string, FormInput>>({});
  const [formRequiredErrors, setFormRequiredErrors] = useState<Record<string, boolean>>({});
  const [formFormulaFieldsMap, setFormFormulaFieldsMap] = useState<Record<string, string[]>>({});
  const [formulaFieldIds, setFormulaFieldIds] = useState<string[]>([]);
  const [conditionalColumns, setConditionalColumns] = useState<Record<string, TableColumnType[]>>({});
  const [currentFocus, setCurrentFocus] = useState<string | null>(null);
  const [isWaitingForExternalRecords, setIsWaitingForExternalRecords] = useState<boolean>(false);

  const { currentProjectId, currentRecordId } = useTableActionsState();
  const { currentSearchContextProjectId, currentSearchContextRecordId } = useSearchQueryParams();
  const { schemaInstance } = useSchemaState();
  const { userType } = useUserType();
  const currentUser = useCurrentUser();
  const { data: recordTypesData, isFetched: recordTypesFetched } = useRecordTypes();

  const { data: allPages, isLoading: allPageDataLoading } = useAllPagesLite({
    refetchOnWindowFocus: false,
    refetchInterval: false,
    staleTime: Infinity
  });
  const { data: relationPages, getRelationPages } = useRelationPages(["sections"]);
  const { results, setMultiQueries } = useMultiTableData();

  const finalColumnOptions = useMemo(() => {
    return !schemaInstance?.extendedSchema || !recordTypesFetched
      ? []
      : (pageData?.columns
          ?.map((col) =>
            appendRecordTypeLookupToCol(
              col,
              recordTypesData || [],
              pageData?.table_name,
              schemaInstance?.extendedSchema
            )
          )
          ?.filter(Boolean) as TableColumnType[]) || [];
  }, [pageData?.columns, recordTypesData, pageData?.table_name, schemaInstance?.extendedSchema, recordTypesFetched]);

  // if inlineCreate use Passed columns otherwise if pageData is present use pageData columns else columns
  const finalColumns = isInlineCreate || !finalColumnOptions?.length ? columns : finalColumnOptions;

  const parentRecordPageId = useMemo(() => {
    return allPages?.find((page) => page.path === `/${parentRecordSlug}` && page.user_type === userType)?.id;
  }, [parentRecordSlug, allPages, userType]);

  const viewSections = useMemo(() => {
    const finalSections: ViewSection[] = [];
    if ((!isCustomView && !pageViewSections?.length) || (isCustomView && !customViewSections?.length)) {
      return finalSections;
    }

    const currentPageViewSectionsConfig = pageViewSections?.find((view) =>
      pageFormViewId
        ? view.viewType === ViewOption.FORM && `${view.viewId}` === `${pageFormViewId}`
        : view.viewType === ViewOption.FORM
    );
    const finalViewSections = isCustomView ? customViewSections : currentPageViewSectionsConfig?.sections;
    finalViewSections?.forEach((section) => {
      if (!!currentUser?.is_admin || !section.isAdminOnly) {
        const sectionInput: ViewSection = {
          ...section,
          columns: []
        };
        // We append the columns to the sections
        if (finalColumns?.length) {
          const finalViewCols = isCustomView ? section.viewColumns : section.pageViewColumns;
          const colsIdsInSection = finalViewCols?.map((pvCol) => pvCol.columnId);
          finalColumns.forEach((col) => {
            if (colsIdsInSection?.includes(col.id)) {
              (sectionInput.columns as TableColumnType[]).push(col);
            }
          });
        }
        finalSections.push(sectionInput);
      }
    });

    return finalSections?.length ? sortBy(finalSections, "sort") : finalSections;
  }, [currentUser?.is_admin, finalColumns, pageViewSections, pageFormViewId, isCustomView, customViewSections]);

  const { data: parentRecordPage } = usePageDataById(parentRecordPageId);

  const { data: recordData } = useTableDataById(
    {
      slug: tablePath || "",
      id: action === CrudActions.UPDATE && record?.id ? record?.id : "",
      tableName: pageData?.table_name,
      columns: pageData?.columns?.filter((col) => col.views?.[ViewOption.FORM]),
      source: "useTransformFormData"
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!pageData?.table_name && action === CrudActions.UPDATE && record?.id
    }
  );

  const isJoinTable = useMemo(
    () => !!(pageData && schemaInstance?.extendedSchema[pageData?.table_name]?.compositePk?.length),
    [pageData, schemaInstance?.extendedSchema]
  );

  const parentRecord = useMemo(() => {
    if (!isJoinTable || !allPages?.length || !pageData?.table_name || !schemaInstance?.extendedSchema) return;

    return getParentRecord({
      tableName: pageData.table_name,
      allPages,
      extendedSchema: schemaInstance.extendedSchema,
      parentRecordId: parentRecordId,
      parentPath: parentRecordSlug,
      userType
    });
  }, [
    isJoinTable,
    allPages,
    schemaInstance?.extendedSchema,
    pageData?.table_name,
    parentRecordId,
    parentRecordSlug,
    userType
  ]);

  // Returns parent record when parent table is a foreign key column in current table
  // ** Will need to be extended to support other pages
  // ** Will not work if parent table is a join table
  // ** Will not work in nested view as it uses url segments
  const parentRecordForForeignKey = useMemo(() => {
    if (isJoinTable || !allPages?.length || !pageData?.table_name) {
      return null;
    }

    const parentRecordPageDetailTabs = parentRecordPage?.views?.find(
      (view) => view.viewType === ViewOption.DETAIL_MAIN
    )?.tabs;
    if (!parentRecordPage || !parentRecordId || !parentRecordPageDetailTabs?.length) return null;

    const tabDetails = parentRecordPageDetailTabs
      ?.filter(
        (tab) =>
          (tab.visibility === DETAIL_TAB_VISIBILITY.DETAIL_TAB || tab.visibility === DETAIL_TAB_VISIBILITY.ALL) &&
          tab.page?.id
      )
      .find((tab) => (tab.page?.path || "").replace("/", "") === tabSlug);

    if (!tabDetails) return null;
    const { filterRelations } = tabDetails;

    if (filterRelations?.["0"]?.lookupTableName) {
      return null;
    }
    const parentRecordForeignKey = filterRelations?.["0"]?.lookupColumn;

    if (!parentRecordForeignKey) return null;

    return {
      name: parentRecordForeignKey,
      record: {
        id: parentRecordId
      }
    };
  }, [isJoinTable, pageData?.table_name, allPages, tabSlug, parentRecordId, parentRecordPage]);

  const compositeKeyColumns = useMemo(() => {
    if (!pageData?.table_name) return;
    if (isJoinTable) {
      const compositeKey = schemaInstance?.extendedSchema[pageData?.table_name].compositePk;
      if (compositeKey) {
        return pageData?.columns
          ?.filter((col) => compositeKey.map((key) => key.attributeId).includes(col.name))
          .map((col) => col.id);
      }
    }
    return;
  }, [pageData?.columns, schemaInstance?.extendedSchema, pageData?.table_name, isJoinTable]);

  const formRelationPageIds = useMemo(
    () =>
      finalColumns
        ?.map((column) =>
          pageFormViewId
            ? column.customPageViews?.[pageFormViewId]?.id &&
              !!column.customPageViews?.[pageFormViewId]?.formRelationPageId
              ? column.customPageViews?.[pageFormViewId]?.formRelationPageId
              : null
            : column.views?.[ViewOption.FORM]?.id && !!column.views?.[ViewOption.FORM]?.formRelationPageId
              ? column.views?.[ViewOption.FORM]?.formRelationPageId
              : null
        )
        .filter(Boolean),
    [finalColumns, pageFormViewId]
  );

  const formColumns = useMemo(() => {
    if (isCustomView) {
      return finalColumns;
    }

    return getFormColumns({
      pageData,
      columns: finalColumns?.filter((col) =>
        pageFormViewId ? col?.customPageViews?.[pageFormViewId]?.id : col?.views?.[ViewOption.FORM]?.id
      ),
      allPages: relationPages,
      action,
      pageFormViewId
    });
  }, [relationPages, action, pageData, finalColumns, isCustomView, pageFormViewId]);

  const relationFormColumnPagesMap = useMemo(() => {
    if (!relationPages?.length || !finalColumns?.length) return {};
    return getRelationFormColumnPagesMap(finalColumns, relationPages);
  }, [finalColumns, relationPages]);

  const joinTableRelationInfo: FormJoinTableRelationInfo[] = useMemo(
    () =>
      Object.keys(relationFormColumnPagesMap).map((relationField) => {
        const relationTablepage = relationFormColumnPagesMap[relationField];
        return {
          tableName: relationTablepage.table_name,
          columns: relationTablepage.columns?.filter((col) => !!col.views?.[ViewOption.FORM]?.id),
          relationField
        };
      }),
    [relationFormColumnPagesMap]
  );

  const relationFormColumnRecordQueries = useMemo(() => {
    if (!recordData) return [];
    return Object.keys(relationFormColumnPagesMap).map((relationField) => {
      const relationTablepage = relationFormColumnPagesMap[relationField];
      return {
        id: recordData[relationField],
        tableName: relationTablepage.table_name,
        columns: relationTablepage.columns?.filter((col) => !!col.views?.[ViewOption.FORM]?.id)
      };
    });
  }, [recordData, relationFormColumnPagesMap]);

  const { data: relationRecordsData, isLoading: relationRecordLoading } = useMultipleTablesData(
    relationFormColumnRecordQueries,
    {
      refetchOnWindowFocus: false
    }
  );

  const finalRelationRecordsData: RelationRecordsData[] | null = useMemo(() => {
    if (!relationRecordsData || relationRecordLoading) return null;
    return relationFormColumnRecordQueries.map((query, index) => {
      return {
        columns: query.columns,
        data: relationRecordsData[index]
      };
    });
  }, [relationRecordsData, relationFormColumnRecordQueries, relationRecordLoading]);

  const fileCol = finalColumns?.find((column) => column.type === CellType.FILE);

  const conditionalColumnIds = useMemo(() => {
    if (!formColumns?.length) return;
    return formColumns
      .map(
        (col) =>
          col.conditionalViewRules?.columnRules?.[0]?.column?.id || col.conditionalViewRules?.columnRules?.[0]?.columnId
      )
      .filter(Boolean);
  }, [formColumns]);

  const isFormValid = (data: Record<string, any>) => {
    if (!pageData?.id) {
      console.error("Form page data not found ", tablePath);
      return;
    }
    const { views } = pageData;
    const formConfig = views?.find((view) => view.viewType === ViewOption.FORM);
    const updatedRequiredErrors: Record<string, boolean> = {};
    let isValid = true;
    if (formConfig?.requiredColumns?.length) {
      formConfig.requiredColumns.forEach((colId) => {
        if (!formColumns?.find((col) => col.id === colId)) return;
        if (compositeKeyColumns?.includes(colId) && action === CrudActions.UPDATE) {
          return;
        }
        const col = pageData.columns?.find((col) => col.id === colId);
        if (
          ((!pageFormViewId && !col?.views?.[ViewOption.FORM]?.formRelationPageId) ||
            (pageFormViewId && !col?.customPageViews?.[pageFormViewId]?.formRelationPageId)) &&
          !data[colId] &&
          isEmpty(data[colId])
        ) {
          updatedRequiredErrors[colId] = true;
          isValid = false;
        }
      });
    }

    Object.values(relationFormColumnPagesMap).forEach((page) => {
      const pageFormConfig = views?.find((view) => view.viewType === ViewOption.FORM);
      if (pageFormConfig?.requiredColumns?.length) {
        pageFormConfig.requiredColumns.forEach((colId) => {
          if (!data[colId] && isEmpty(data[colId])) {
            updatedRequiredErrors[colId] = true;
            isValid = false;
          }
        });
      }
    });
    setFormRequiredErrors(updatedRequiredErrors);
    return isValid;
  };

  // Used to change form fields based on other form fields
  // Only called by rollup source columns or columns that are in formulas
  const handleFieldChange = useCallback(
    (
      values: string | number | boolean | RecordItem | SelectOption,
      columnId: string,
      resetField?: (name: string, options?: { defaultValue: any }) => void,
      resetKey?: (newValue: any, colId: string) => void,
      allFormValues?: RecordItem
    ) => {
      if (typeof resetField !== "function") return;
      const formulaUpdatedFields: RecordItem = {};
      if (conditionalColumnIds?.includes(columnId)) {
        const selectedColumn = formColumns?.find((col) => col.id === columnId);
        // Check if value matches to display conditional column
        const columnsWithCondition = conditionalColumns[columnId];
        if (selectedColumn && columnsWithCondition?.length) {
          columnsWithCondition.forEach((colWithCondition) => {
            const showConditionalColumn = isFormConditionalColumnToShow(selectedColumn, colWithCondition, values);

            if (showConditionalColumn) {
              const { columnInitValue, isReadOnly } = getFormColumnInitValue({
                action,
                recordData,
                finalRelationRecordsData,
                column: colWithCondition,
                isJoinTable,
                parentRecord,
                extendedSchema: schemaInstance?.extendedSchema,
                parentRecordForForeignKey,
                currentUserId: currentUser?.id,
                currentProjectId:
                  trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
                    ? currentSearchContextProjectId
                    : currentProjectId,
                currentRecordId:
                  trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextRecordId
                    ? currentSearchContextRecordId
                    : currentRecordId,
                results,
                isInlineCreate,
                prefillValues,
                tablePath
              });
              const newFormField = {
                id: colWithCondition.id,
                name: colWithCondition.name,
                label: colWithCondition.header,
                type: colWithCondition.type,
                isError: false,
                column: colWithCondition,
                isReadOnly,
                columnInitValue
              };
              setFormFields((prev) => ({ ...prev, [colWithCondition.id]: newFormField }));
            } else {
              setFormFields((prev) => {
                const { [colWithCondition.id]: _, ...rest } = prev;
                return rest;
              });
            }
          });
        }
      }
      if (formulaFieldIds.includes(columnId)) {
        try {
          const updatedFinalData = getUpdatedFormulaColumns({
            updatedColumnId: columnId,
            updatedValue: values,
            finalRecord: { ...(allFormValues || {}), [columnId]: values },
            pageColumns: formColumns || [],
            formulaFieldIds,
            formFormulaFieldsMap,
            isForm: true
          });
          if (!isEmpty(updatedFinalData)) {
            Object.keys(updatedFinalData || {}).forEach((colId: string) => {
              const formulaResult = updatedFinalData?.[colId];
              if (Number.isFinite(formulaResult)) {
                resetField(colId, {
                  defaultValue: formulaResult
                });
                resetKey?.(formulaResult, colId);
                setCurrentFocus(columnId);
                formulaUpdatedFields[colId] = formulaResult;
              }
            });
          }
        } catch (err) {
          console.log("@@ Error in formula ", err);
        }
      }
      const rollupUpdatedColumnIds: string[] = [];
      const rollupUpdatedRecord = { ...allFormValues, ...formulaUpdatedFields };
      formColumns?.forEach((col: TableColumnType) => {
        if (col.isRollup) {
          const { rollupConfig } = col;
          if (rollupConfig?.sourceColId === columnId) {
            if ((values as SelectOption)?.optionData?.[rollupConfig?.sourceColColumn]) {
              resetField(col.id, {
                defaultValue: (values as SelectOption)?.optionData?.[rollupConfig?.sourceColColumn]
              });
              resetKey?.((values as SelectOption)?.optionData?.[rollupConfig?.sourceColColumn], col.id);
              rollupUpdatedColumnIds.push(col.id);
              rollupUpdatedRecord[col.id] = (values as SelectOption)?.optionData?.[rollupConfig?.sourceColColumn];
              setCurrentFocus(columnId);
            }
          }
        }
      });
      // Check if any formula fields need to be updated
      if (rollupUpdatedColumnIds.length) {
        const updatedColumnsAndValues = getRollupFormulaColumnValues({
          rollupUpdatedColumnIds,
          allFormValues: { ...allFormValues, ...rollupUpdatedRecord },
          formFormulaFieldsMap,
          formulaFieldIds,
          formColumns: formColumns || [],
          inForm: true
        });
        if (Object.keys(updatedColumnsAndValues).length) {
          Object.keys(updatedColumnsAndValues).forEach((colId: string) => {
            const formulaResult = updatedColumnsAndValues?.[colId];
            if (Number.isFinite(formulaResult)) {
              resetField(colId, {
                defaultValue: formulaResult
              });
              resetKey?.(formulaResult, colId);
              setCurrentFocus(columnId);
            }
          });
        }
      }
    },
    [
      formColumns,
      formulaFieldIds,
      formFormulaFieldsMap,
      conditionalColumns,
      conditionalColumnIds,
      setCurrentFocus,
      setFormFields,
      action,
      recordData,
      finalRelationRecordsData,
      isJoinTable,
      parentRecord,
      schemaInstance?.extendedSchema,
      parentRecordForForeignKey,
      currentUser?.id,
      currentProjectId,
      currentRecordId,
      results,
      isInlineCreate,
      prefillValues,
      tablePath,
      trigger,
      currentSearchContextProjectId,
      currentSearchContextRecordId
    ]
  );

  useEffect(() => {
    if (!formRelationPageIds?.length) return;
    getRelationPages(formRelationPageIds as string[]);
  }, [formRelationPageIds, getRelationPages]);

  useEffect(() => {
    if (!formColumns?.length) return;
    let finalFormulaColIds: string[] = [];
    const formulaColMap: { [colId: string]: string[] } = {};
    const externalRecordQueries: MultiQuery[] = [];
    formColumns.forEach((col: TableColumnType) => {
      if (col.isFormula) {
        const { formula } = col;
        if (formula) {
          const formulaIds = formula.match(/\{(.*?)\}/g);
          const formulaColIds = formulaIds?.map((id) => id.replace("{", "").replace("}", ""));
          if (formulaColIds) {
            finalFormulaColIds = finalFormulaColIds.concat(formulaColIds);
            formulaColMap[col.id] = formulaColIds;
          }
        }
      }
      if (col.name && (col.defaultValues?.value as ExternalDefaultValue)?.isExternalRecord) {
        const externalDefault = col.defaultValues?.value as ExternalDefaultValue;
        if (externalDefault?.tableName && externalDefault?.columnName && externalDefault?.rule?.field) {
          externalRecordQueries.push({
            tableName: externalDefault.tableName,
            columnNames: [externalDefault.columnName, externalDefault.rule.field],
            field: externalDefault.rule.field,
            operator: externalDefault.rule.operator,
            value: externalDefault.rule.value,
            resultId: col.id
          });
        }
      }
    });
    if (!isEmpty(formulaColMap)) {
      setFormulaFieldIds(finalFormulaColIds);
      setFormFormulaFieldsMap(formulaColMap);
    }
    if (externalRecordQueries?.length) {
      setIsWaitingForExternalRecords(true);
      setMultiQueries(externalRecordQueries);
    }
  }, [formColumns, setMultiQueries]);

  useEffect(() => {
    if (
      !formColumns?.length ||
      (action === CrudActions.UPDATE && record?.id && !recordData?.id) ||
      relationRecordLoading ||
      (!isCustomView && !pageData?.id)
    ) {
      return;
    }
    // Pick all formula columns and columns within formulas
    let finalFormulaColIds: string[] = [];
    const formulaColMap: { [colId: string]: string[] } = {};
    const conditionalColIds: string[] = [];

    formColumns.forEach((col: TableColumnType) => {
      if (col.isFormula) {
        const { formula } = col;
        if (formula) {
          const formulaIds = formula.match(/\{(.*?)\}/g);
          const formulaColIds = formulaIds?.map((id) => id.replace("{", "").replace("}", ""));
          if (formulaColIds) {
            finalFormulaColIds = finalFormulaColIds.concat(formulaColIds);
            formulaColMap[col.id] = formulaColIds;
          }
        }
      }
      if (
        col.conditionalViewRules?.columnRules?.[0]?.columnId ||
        col.conditionalViewRules?.columnRules?.[0]?.column?.id
      ) {
        const colId =
          col.conditionalViewRules.columnRules?.[0]?.column?.id || col.conditionalViewRules?.columnRules?.[0]?.columnId;
        conditionalColIds.push(colId as string);
      }
    });
    const updatedFormFields: Record<string, FormInput> = {};
    const updatedConditionalFields: Record<string, TableColumnType[]> = {};

    formColumns.forEach((column: TableColumnType) => {
      // Check if form column has conditional viewing
      if (
        column.conditionalViewRules?.ruleConfig?.views?.includes(ViewOption.FORM) &&
        column?.conditionalViewRules?.columnRules?.length
      ) {
        for (let i = 0; i < column?.conditionalViewRules?.columnRules?.length; i++) {
          const colRule = column.conditionalViewRules.columnRules?.[i];
          const finalColId = colRule?.column?.id || colRule?.columnId;
          if (finalColId) {
            updatedConditionalFields[finalColId as string] = updatedConditionalFields[finalColId as string]
              ? [...updatedConditionalFields[finalColId as string], column]
              : [column];
            return;
          }
        }
      }

      if (action === CrudActions.UPDATE && isJoinTable && pageData?.table_name) {
        const compositeKeys = schemaInstance?.extendedSchema[pageData.table_name].compositePk;
        if (compositeKeys && compositeKeys.map((key) => key.attributeId).includes(column.name)) {
          return;
        }
      }

      let onChangeCallback;
      if (column.isRollupSource || finalFormulaColIds.includes(column.id) || conditionalColIds.includes(column.id)) {
        onChangeCallback = handleFieldChange;
      }

      const { columnInitValue, isReadOnly } = getFormColumnInitValue({
        action,
        recordData,
        finalRelationRecordsData,
        column,
        isJoinTable,
        parentRecord,
        extendedSchema: schemaInstance?.extendedSchema,
        parentRecordForForeignKey,
        currentUserId: currentUser?.id,
        currentProjectId:
          trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextProjectId
            ? currentSearchContextProjectId
            : currentProjectId,
        currentRecordId:
          trigger === FORM_TRIGGER_TYPES.SEARCH && currentSearchContextRecordId
            ? currentSearchContextRecordId
            : currentRecordId,
        results,
        isInlineCreate,
        prefillValues,
        tablePath
      });

      updatedFormFields[column.id] = {
        id: column.id,
        name: column.name,
        label: column.header,
        type: column.type,
        isError: false,
        column,
        columnInitValue,
        isReadOnly,
        onChangeCallback
      };
    });

    setFormFields((prev) => ({ ...prev, ...updatedFormFields }));
    if (!isEqual(conditionalColumns, updatedConditionalFields)) {
      setConditionalColumns(updatedConditionalFields);
    }

    // if externalRecord has to be fetched and are fetched mark isWaitingForExternalRecords as false
    if (isWaitingForExternalRecords && Object.keys(results || {}).length) {
      setIsWaitingForExternalRecords(false);
    }
  }, [
    formColumns,
    recordData,
    action,
    pageData,
    relationRecordLoading,
    finalRelationRecordsData,
    isJoinTable,
    schemaInstance?.extendedSchema,
    isInlineCreate,
    handleFieldChange,
    prefillValues,
    tablePath,
    parentRecord,
    parentRecordForForeignKey,
    conditionalColumns,
    record?.id,
    currentUser?.id,
    isCustomView,
    isWaitingForExternalRecords,
    results,
    currentProjectId,
    currentRecordId,
    trigger,
    currentSearchContextProjectId,
    currentSearchContextRecordId
  ]);

  const formFieldsArray = useMemo(() => {
    const formFieldsArrayList = Object.keys(formFields).map((key) => formFields[key]);
    if (!isJoinTable) {
      return sortBy(formFieldsArrayList, (col) =>
        pageFormViewId ? col.column?.customPageViews?.[pageFormViewId].sort : col.column?.sortOrder?.[ViewOption.FORM]
      );
    }

    return formColumns?.reduce((acc: FormInput[], col) => {
      const colFormField = formFieldsArrayList.find((formField) => formField.column?.id === col.id);
      if (colFormField) {
        acc.push(colFormField);
      }
      return acc;
    }, []);
  }, [formFields, isJoinTable, formColumns, pageFormViewId]);

  const sections = useMemo(() => {
    if (!formFieldsArray?.length) return [];
    const formConfig = pageData?.views?.find((view) =>
      pageFormViewId ? `${view.viewId}` === `${pageFormViewId}` : view.viewType === ViewOption.FORM && view.isDefault
    );
    const formCols = formConfig?.columns || [];

    const formSections = formCols.reduce((acc: ViewSection[], column) => {
      if (column?.formRelationPageId) {
        const relationPage = relationPages?.find((page) => page.id === column.formRelationPageId);
        if (relationPage) {
          const relationPageFormConfig = relationPage?.views?.find((view) => view.viewType === ViewOption.FORM);
          acc.push(...(relationPageFormConfig?.sections || []));
        }
      }
      return acc;
    }, []);

    if (viewSections?.length) {
      formSections.push(...viewSections);
    }
    formSections.push(...(formConfig?.sections || []));
    if (!!formSections?.length) {
      const allColumnsWithSections: string[] = [];
      const columnsBySections: any = {};
      formSections.forEach((section) => {
        const { pageViewColumns, viewColumns } = section;
        const finalViewCols = isCustomView ? viewColumns : pageViewColumns;
        if (finalViewCols?.length) {
          if (!columnsBySections[section.id]) {
            columnsBySections[section.id] = {
              id: section?.id,
              title: section?.title,
              columns: []
            };
          }
          finalViewCols.forEach((pageViewCol) => {
            const col = formColumns?.find((colVal) => colVal.id === pageViewCol.columnId);
            if (col && !col?.views?.[ViewOption.FORM]?.isHidden) {
              columnsBySections[section.id].columns.push(col.id);
              allColumnsWithSections.push(col.id);
            }
          });
        }
      });

      const sectionWithColumns: any =
        sortBy(formSections, "sort")
          .map((section) => {
            const finalFormSection = {
              title: section.title,
              description: section.description,
              isClose: section.isClose,
              columns: formFieldsArray.filter((item) => {
                const columnId = item.id;
                return columnsBySections?.[section.id]?.columns?.includes(columnId);
              })
            };
            if (!finalFormSection.columns?.length) {
              return undefined;
            }
            return finalFormSection;
          })
          ?.filter(Boolean) || [];

      const emptyColumns = {
        columns: formFieldsArray?.filter((item) => !allColumnsWithSections.includes(item.id))
      };
      return [...sectionWithColumns, emptyColumns].filter((item) => item.columns?.length);
    }

    return [{ columns: formFieldsArray }];
  }, [formFieldsArray, pageData?.views, viewSections, formColumns, relationPages, pageFormViewId, isCustomView]);

  useEffect(() => {
    if (!sections || currentFocus) return;

    const firstSectionWithTextCol = sections.find((section) =>
      section.columns?.some((col: RecordItem) => TEXT_TYPE_CELLS.includes(col.column.type))
    );
    const firstTextColumn = firstSectionWithTextCol?.columns.find((col: RecordItem) =>
      TEXT_TYPE_CELLS.includes(col.type)
    );
    if (firstTextColumn) {
      setCurrentFocus(firstTextColumn.id);
    }
  }, [sections, currentFocus]);

  const finalPageData = useMemo(() => {
    if (!pageData) return pageData;
    return {
      ...pageData,
      columns: finalColumnOptions
    };
  }, [pageData, finalColumnOptions]);

  return {
    formColumns,
    currentPageData: finalPageData,
    recordData,
    joinTableRelationInfo,
    loading:
      (relationFormColumnRecordQueries?.length && relationRecordLoading) ||
      allPageDataLoading ||
      isWaitingForExternalRecords,
    relationFormColumnPagesMap,
    fileCol,
    isJoinTable,
    formFields,
    isFormValid,
    currentFocus,
    formFieldsArray,
    formRequiredErrors,
    sections,
    parentRecordForForeignKey, // For debugging purposes only
    allColumns: finalColumns
  };
};

export default useTransformFormData;
