import { PostgrestError } from "@supabase/supabase-js";

const ErrorView = ({ error }: { error?: Error | PostgrestError }) => {
  return (
    <div className="flex flex-col items-center p-3">
      <p className="p-5">There was an error loading this page, please refresh or contact an admin for assistance..</p>
      {error?.message ? (
        <div className="border-1 m-5 w-full rounded-md border border-red-800 bg-neutral-100 p-3 text-center">
          {error.message}
        </div>
      ) : null}
    </div>
  );
};

export default ErrorView;
