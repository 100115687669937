import clsx from "clsx";
import { Loader as SpinnerIcon } from "lucide-react";

const DataLoader = ({ className }: { className?: string }) => {
  return (
    <div className={clsx("flex h-full w-full items-center justify-center pb-4", className)}>
      <div className="flex items-center gap-2 rounded-lg bg-white px-4 py-2 font-medium text-base-primary shadow-100 dark:bg-base-primary dark:text-base-dark-primary">
        <SpinnerIcon role="status" className={"spinner-border inline-block h-6 w-6 animate-spin rounded-full"} />
        <span>Loading...</span>
      </div>
    </div>
  );
};
export default DataLoader;
