"use client";
import { useCallback, useMemo, useState } from "react";

import { useQueries } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { PaginationState, SortItem, TableColumnType, TableFilterType } from "types/baTypes";
import { QueryHookOptions, RecordItem } from "types/common";
import { getTableData } from "lib/supabaseApi";
import { getCompositeKeysFromCols } from "utils/dataUtils";
import useSchemaState from "./useSchemaState";
import useCurrentUser from "./useCurrentUser";
import useRecordTypes from "./useRecordTypes";

type useOrFilteredDataProps = {
  tableName: string;
  sorting?: SortItem[];
  columns: TableColumnType[];
  tableFiltersOption?: { filters: TableFilterType[] };
  pagination?: PaginationState;
  globalSort?: SortItem[];
  hookOptions?: QueryHookOptions;
  queryKey?: string;
  returnCountOnly?: boolean;
  skipOrgIdFilter?: boolean;
};

const useOrFilteredData = ({
  tableName,
  sorting = [],
  columns,
  tableFiltersOption,
  pagination,
  globalSort,
  returnCountOnly,
  skipOrgIdFilter
}: useOrFilteredDataProps) => {
  const { schemaInstance } = useSchemaState();
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();
  const { data: recordTypesData } = useRecordTypes();

  const [filterQueries, setFilterQueries] = useState([]);

  const results = useQueries({
    queries: filterQueries
  });

  const compositeKeysByTable = useMemo(() => {
    return getCompositeKeysFromCols(columns, schemaInstance?.extendedSchema);
  }, [columns, schemaInstance?.extendedSchema]);

  const getDataForOrFilters = useCallback(
    (filters: TableFilterType[]) => {
      if (!filters.length) return;
      const finalFilterSet: RecordItem = [];
      filters.forEach((filter) => {
        if (filter.filterGroup?.length) {
          finalFilterSet.push(...filter.filterGroup);
        }
      });
      const finalFilterQueries: any = [];

      finalFilterSet.forEach((filter: TableFilterType) => {
        finalFilterQueries.push({
          queryKey: ["table", "or-filter", tableName, filter],
          queryFn: () =>
            getTableData({
              tableName,
              columns,
              tableFiltersOption,
              sorting,
              supabaseClient,
              filters: [{ ...filter, filterLookupPath: filter.column?.lookupPath }],
              pagination,
              compositeKeysByTable,
              currentUser,
              globalSort,
              extendedSchema: schemaInstance?.extendedSchema,
              recordTypesData,
              returnCountOnly,
              skipOrgIdFilter
            }),
          staleTime: Infinity
        });
      });
      setFilterQueries(finalFilterQueries);
    },
    [
      columns,
      currentUser,
      globalSort,
      pagination,
      compositeKeysByTable,
      sorting,
      supabaseClient,
      tableFiltersOption,
      tableName,
      schemaInstance?.extendedSchema,
      recordTypesData,
      returnCountOnly,
      skipOrgIdFilter
    ]
  );

  return {
    results: results?.length ? results : undefined,
    getDataForOrFilters,
    isLoading: !!filterQueries?.length && results?.some((result) => result?.isLoading)
  };
};

export default useOrFilteredData;
