import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import uniqBy from "lodash/uniqBy";
import isEqual from "lodash/isEqual";
import { PostgrestError } from "@supabase/supabase-js";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import { getTableData } from "lib/supabaseApi";
import { QueryHookOptions, RecordItem } from "types/common";
import { SortItem, TableColumnType, TableFilterType, PaginationState } from "types/baTypes";
import { ALTERNATE_SCHEMAS, ERROR_TYPES, FILTER_OPERATOR, SKIP_ORG_ID_FILTER_KEY } from "utils/constants";
import { getCompositeKeysFromCols } from "utils/dataUtils";
import useSupabaseAltClient from "./useSupabaseAltClient";
import useCurrentUser from "./useCurrentUser";
import useSchemaState from "./useSchemaState";
import useOrFilteredData from "./useOrFilteredData";
import useRecordTypes from "./useRecordTypes";
import useErrorLogger from "./useErrorLogger";

type useTableDataProps = {
  tableName: string;
  sorting?: SortItem[];
  columns: TableColumnType[];
  tableFiltersOption?: { filters: TableFilterType[] };
  filters?: TableFilterType[];
  pagination?: PaginationState;
  globalSort?: SortItem[];
  hookOptions?: QueryHookOptions;
  queryKey?: string;
  isViewTable?: boolean;
  isViewFilterSort?: boolean; // When a view is being used for filtering and sorting (currently only used for task library)
  altSchema?: ALTERNATE_SCHEMAS; // When data is fetched from alternate schema
  returnCountOnly?: boolean; // When only count is needed
  inAddMany?: boolean; // When data is being fetched for add many
  source?: string; // can used to debug source of hook call
  isAggregateFetch?: boolean; // When data is being fetched with aggregate functions
  disableOrgIdFilter?: boolean; // When org_id filter is not needed
};

const useTableData = ({
  tableName,
  sorting,
  columns,
  tableFiltersOption,
  filters,
  pagination,
  globalSort,
  hookOptions,
  queryKey = "table",
  isViewTable = false,
  isViewFilterSort = false,
  altSchema,
  returnCountOnly = false,
  inAddMany = false,
  isAggregateFetch = false,
  disableOrgIdFilter = false
}: useTableDataProps) => {
  const { schemaInstance, schema } = useSchemaState();
  const supabaseClient = useSupabaseBrowser();
  const schemaClientMap = useSupabaseAltClient();
  const currentUser = useCurrentUser();
  const { logError } = useErrorLogger();
  const { data: recordTypesData, isFetched: recordTypesFetched } = useRecordTypes();
  const [orFilters, setORFilters] = useState<TableFilterType[]>();
  const [finalData, setFinalData] = useState<any[] | null | undefined>();
  const [resultCount, setResultCount] = useState<number | undefined>();

  const skipOrgIdFilter = !!(disableOrgIdFilter && currentUser?.is_super_admin);
  const finalFilters = useMemo(() => {
    if (!filters) return;
    const updatedFilters: TableFilterType[] = [];
    filters.forEach((filter) => {
      if (filter.filterOperator !== FILTER_OPERATOR.OR) {
        updatedFilters.push(filter);
      } else {
        // create new orFilters by grouping filter.isLookup = false together and filter.isLookup = true together by their lookupPath[0].tableName
        const baseTableFilters: TableFilterType[] = [];
        const lookupFilters: TableFilterType[] = [];
        filter.filterGroup?.forEach((orGroupfilter) => {
          if (orGroupfilter.column?.isLookup) {
            if (orGroupfilter.column?.lookupPath?.[0]) {
              lookupFilters.push(orGroupfilter);
            }
          } else {
            baseTableFilters.push(orGroupfilter);
          }
        });

        if (baseTableFilters.length) {
          updatedFilters.push({
            filterOperator: FILTER_OPERATOR.OR,
            filterGroup: baseTableFilters,
            isBaseTableOrFilter: true
          });
        }

        if (lookupFilters.length) {
          updatedFilters.push({
            filterOperator: FILTER_OPERATOR.OR,
            filterGroup: lookupFilters
          });
        }
      }
    });

    return updatedFilters;
  }, [filters]);

  const {
    results,
    getDataForOrFilters,
    isLoading: orDataLoading
  } = useOrFilteredData({
    tableName,
    sorting,
    columns,
    tableFiltersOption,
    pagination,
    globalSort,
    hookOptions,
    returnCountOnly,
    skipOrgIdFilter
  });

  useEffect(() => {
    setResultCount(undefined);
  }, [tableName]);

  const finalQueryKey = useMemo(() => {
    const key: any[] = [
      queryKey,
      tableName,
      sorting,
      columns?.map(
        (col) =>
          `${col.id}${col.name}${Object.keys(col?.lookupPath || {})
            ?.map(
              (lookupLvl) =>
                `${col.lookupPath?.[lookupLvl]?.lookupTableName}${col.lookupPath?.[lookupLvl]?.lookupColumns?.join("-")}`
            )
            .join(
              "-"
            )}${col.lookupFilters?.map((filter) => `${filter.filterField}${filter.filterOperator}${JSON.stringify(filter.filterValue)}`).join("-")}`
      ), // Refetch if col name or lookup path/filters changes along with id (happens when editing the column)
      tableFiltersOption?.filters
        ?.map((filter) => `${filter.filterField}${filter.filterOperator}${filter.filterValue}`)
        .join("-"),
      finalFilters
        ?.filter((filter) => filter.filterOperator !== FILTER_OPERATOR.OR || filter.isBaseTableOrFilter)
        ?.map((f, index) =>
          f.filterOperator !== FILTER_OPERATOR.OR
            ? `${f.filterField}${f.filterOperator}${JSON.stringify(f.filterValue)}`
            : `${f.filterOperator}_${index}${f.filterGroup?.length}`
        )
        .join("-"), // Don't refetch if lookup OR filters change that is handled separately
      recordTypesData?.map((item) => item.type),
      inAddMany,
      isAggregateFetch,
      skipOrgIdFilter ? SKIP_ORG_ID_FILTER_KEY : currentUser?.org_id
    ];
    if (pagination) {
      key.push(pagination.currentPage);
      key.push(pagination.pageSize);
    }
    return key;
  }, [
    queryKey,
    tableName,
    sorting,
    columns,
    tableFiltersOption,
    finalFilters,
    pagination,
    recordTypesData,
    inAddMany,
    isAggregateFetch,
    currentUser?.org_id,
    skipOrgIdFilter
  ]);

  const compositeKeysByTable = useMemo(() => {
    if (!schema || !schemaInstance?.extendedSchema) return undefined;
    return getCompositeKeysFromCols(columns, schemaInstance?.extendedSchema);
  }, [columns, schemaInstance?.extendedSchema, schema]);

  const { data, isLoading, refetch, isFetched, error } = useQuery({
    queryKey: finalQueryKey,
    queryFn: () =>
      getTableData({
        tableName,
        columns,
        tableFiltersOption,
        sorting,
        supabaseClient: altSchema && schemaClientMap?.[altSchema] ? schemaClientMap[altSchema] : supabaseClient,
        filters: finalFilters,
        pagination,
        currentUser,
        globalSort,
        compositeKeysByTable,
        recordTypesData,
        extendedSchema: altSchema ? schemaInstance?.alternateSchema?.[altSchema] : schemaInstance?.extendedSchema, // TODO: Resolve this to return specific data only
        returnCountOnly,
        isAggregateFetch,
        skipOrgIdFilter: isViewTable || skipOrgIdFilter
      }),
    enabled:
      !!tableName &&
      !(orFilters?.length && finalFilters?.length === orFilters?.length) &&
      !!Object.keys(schemaInstance?.extendedSchema || {}).length &&
      recordTypesFetched &&
      ((!isViewTable && !!currentUser?.org_id) || isViewTable),
    ...(hookOptions || {})
  });

  // If OR filters, use separate queries for them
  useEffect(() => {
    if (
      !finalFilters?.length ||
      !finalFilters?.some((filter) => filter.filterOperator === FILTER_OPERATOR.OR && !filter.isBaseTableOrFilter) ||
      !columns?.length
    ) {
      if (orFilters?.length) {
        setORFilters(undefined);
      }
      return;
    }
    const orFiltersList = finalFilters.filter(
      (filter) => filter.filterOperator === FILTER_OPERATOR.OR && !filter.isBaseTableOrFilter
    );
    if (!isEqual(orFiltersList, orFilters)) {
      setORFilters(orFiltersList);
      getDataForOrFilters(orFiltersList);
    }
  }, [finalFilters, getDataForOrFilters, orFilters, columns?.length]);

  useEffect(() => {
    if (!finalFilters?.length || !orFilters?.length || isViewFilterSort) {
      if (!isViewFilterSort) {
        setFinalData(data?.data);
        setResultCount(data?.count);
      }
      return;
    }
    if (orDataLoading) {
      return;
    }
    const finalResultsData: RecordItem[] = [];
    let totalCountVal: number | undefined = undefined;
    results?.forEach((result: any) => {
      finalResultsData.push(...(result?.data?.data || []));
      totalCountVal += result?.data?.count || 0;
    });
    const finalResultIds = [...new Set(finalResultsData?.map((item) => item.id))].sort();
    const finalDataIds = [...new Set(finalData?.map((item) => item.id))].sort();

    // Check if table data only has OR filters
    if (orFilters?.length && finalFilters?.length === orFilters?.length) {
      if (!isEqual(finalResultIds, finalDataIds)) {
        setResultCount(totalCountVal);
        setFinalData(finalResultsData?.length ? uniqBy(finalResultsData, "id") : undefined);
      }
      return;
    }
    const dataIds = [...new Set(data?.data?.map((item) => item.id))].sort();
    const finalRespIds = [...new Set([...dataIds, ...finalResultIds])].sort();
    totalCountVal =
      totalCountVal === 0 && data?.count === undefined
        ? undefined
        : totalCountVal !== undefined
          ? totalCountVal + (data?.count || 0)
          : undefined;
    if (!isEqual(finalRespIds, finalDataIds)) {
      setResultCount(totalCountVal);
      // There are other filters with AND so we need to add data from the main query
      setFinalData(uniqBy([...finalResultsData, ...(data?.data || [])], "id"));
    }
  }, [data?.data, results, orFilters, finalFilters?.length, orDataLoading, finalData, data?.count, isViewFilterSort]);

  useEffect(() => {
    if (!finalFilters?.length || !isViewFilterSort || !data?.data) {
      setFinalData(data?.data);
      setResultCount(returnCountOnly ? data?.count : !data?.data?.length && isFetched ? 0 : data?.count);
      return;
    }
    const idsInFilter = finalFilters?.find(
      (filter) => filter.filterOperator === FILTER_OPERATOR.IN && filter.filterField === "id"
    );
    if (!idsInFilter) {
      setFinalData(data?.data);
      setResultCount(returnCountOnly ? data?.count : !data?.data?.length && isFetched ? 0 : data?.count);
      return;
    }
    const ids: string[] | undefined = idsInFilter?.filterValue as string[];
    if (ids?.length) {
      const sortedData = ids.map((id) => data?.data?.find((item) => item.id === id)).filter((item) => !!item?.id);
      setFinalData(sortedData);
    }
  }, [isViewFilterSort, finalFilters, data, returnCountOnly, isFetched]);

  useEffect(() => {
    if (!data?.error) {
      return;
    }
    logError({
      error: data.error as PostgrestError,
      source: "useTableData",
      type: ERROR_TYPES.HOOKS,
      message: (data.error as PostgrestError).message || `Error fetching data for table ${tableName}`,
      url: window.location.href,
      additionalInfo: {
        tableName
      }
    });
  }, [data?.error, tableName, logError]);

  // Don't return any data when hook is disabled, to avoid returning previous data
  return {
    data:
      ("enabled" in (hookOptions || {}) && !!hookOptions?.enabled) || !("enabled" in (hookOptions || {}))
        ? !finalFilters?.length || !orFilters?.length || isViewFilterSort
          ? data?.data
          : finalData
        : undefined,
    isLoading: isLoading || orDataLoading,
    refetch,
    totalCount: resultCount, // TODO: Needs to be fixed for larger numbers
    page: data?.page,
    isFetched,
    finalQueryKey, // Use to invalidate or refetch query
    error: data?.error || (error as Error | PostgrestError | undefined)
  };
};

export default useTableData;
